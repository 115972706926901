import React from "react";
import { AppBar, Box, Divider, Toolbar, Typography } from "@mui/material";

const TravelRule = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom marginBottom={4}>
                Understanding the Travel Rule with TradeBP
            </Typography>

            <Box sx={{ marginBottom: 4 }}>
                <Box>
                    <Typography variant="h6">What is the Travel Rule?</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body1" gutterBottom>
                        The Travel Rule, formally known as the "Financial Action Task Force (FATF) Recommendation 16," is a regulatory standard designed to enhance the transparency and traceability of cryptocurrency transactions. It applies to Virtual Asset Service Providers (VASPs) like TradeBP to help prevent illicit activities such as money laundering and terrorist financing.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Originally introduced for traditional financial institutions, this rule mandates the sharing of specific information between institutions when processing transactions of certain amounts. Now, the Travel Rule extends to cryptocurrency transactions to align the digital asset ecosystem with global regulatory standards.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Box>
                    <Typography variant="h6">Why is the Travel Rule Important?</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body1" gutterBottom>
                        The Travel Rule aims to:
                    </Typography>
                    <ul>
                        <li>Prevent financial crimes like money laundering and terrorism financing.</li>
                        <li>Ensure transparency in transactions between VASPs.</li>
                        <li>Promote trust and accountability within the crypto industry.</li>
                    </ul>
                    <Typography variant="body1" gutterBottom>
                        Failure to comply with the Travel Rule can lead to severe penalties, reputational damage, and operational disruptions.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Box>
                    <Typography variant="h6">How Does the Travel Rule Work?</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body1" gutterBottom>
                        For transactions involving $1,000 (or equivalent in local currency) or more, TradeBP will collect and share specific information about the transaction originator and beneficiary. This ensures compliance and enhances the legitimacy of the transaction process.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Box>
                    <Typography variant="h6">How TradeBP Ensures Compliance</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body1" gutterBottom>
                        TradeBP is committed to ensuring smooth adherence to the Travel Rule through:
                    </Typography>
                    <ul>
                        <li>Implementing robust technology solutions for secure data sharing.</li>
                        <li>Verifying customer information as required by global regulatory standards.</li>
                        <li>Maintaining a secure, private, and compliant transaction environment.</li>
                    </ul>
                    <Typography variant="body1" gutterBottom>
                        We also utilize trusted solutions like TRUST (Travel Rule Universal Solution Technology), enabling us to securely exchange compliance information without compromising customer privacy.
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Box>
                    <Typography variant="h6">What You Need to Do</Typography>
                    <Divider sx={{ my: 1 }} />
                    <ul>
                        <li>For withdrawals over $1,000: Ensure your wallet address is verified in advance to prevent delays.</li>
                        <li>For deposits: Be prepared to provide additional information if your wallet or transaction details are not recognized automatically.</li>
                    </ul>
                </Box>
            </Box>

            <Box>
                <Box>
                    <Typography variant="h6">Looking Ahead</Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body1" gutterBottom>
                        Compliance with the Travel Rule requires ongoing monitoring and investment in cutting-edge security and compliance technologies. At TradeBP, we are committed to protecting your data, ensuring compliance, and fostering trust within the cryptocurrency ecosystem.
                    </Typography>
                    <Typography variant="body1">
                        For any inquiries or further guidance, feel free to contact TradeBP Support.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};


const articles = [
    {
        title: "What is the Travel Rule?",
        content: <TravelRule />,
    },
    {
        title: "How to Verify Your Account",
        content: "This article will guide you through the process of verifying your account.",
    }
];

const Support = () => {
    const [selectedArticle, setSelectedArticle] = React.useState(0);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                width: "100%",
                backgroundColor: "background.default",
            }}
        >
            <AppBar 
                position="static"
                sx={{
                    bgcolor: 'white',
                    height: { xs: '70px', md: '80px' }
                }}
            >
                <Toolbar
                    sx={{
                        height: '100%',
                        padding: { xs: '0 16px', md: '0 24px' }
                    }}
                >
                    <Box
                        component="img"
                        src="assets/logos/tradeBp.svg"
                        alt="TradeBP Logo"
                        sx={{
                            height: { xs: '30px', md: '40px' },
                            marginLeft: 4,
                            width: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                </Toolbar>
            </AppBar>

            <Box
                sx={{
                    width: '100%',
                    background: 'linear-gradient(180deg, #2e1065 0%, #5b21b6 50%, #7e22ce 100%)',
                    padding: { xs: '28px 14px', md: '44px 22px' },
                    textAlign: 'center'
                }}
            >
                <Typography 
                    variant="h3"
                    color="white"
                    sx={{
                        fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' },
                        margin: '0 auto',
                        lineHeight: 1.2
                    }}
                >
                    Welcome to TradeBP help center
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    padding: "40px",
                    "@media (max-width: 1000px)": { 
                        flexWrap: "wrap",
                        padding: "20px",
                    },
                    "@media (min-width: 1500px)": { 
                        marginInline: "8%",
                        gap: "20px",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        minWidth: "320px",
                        maxWidth: "320px",
                        padding: "20px",
                        "@media (max-width: 1000px)": { 
                            minWidth: "100%", maxWidth: "100%"
                        },
                    }}
                >
                    <Typography variant="h4">Articles</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "20px",
                        }}
                    >
                        {articles.map((article, index) => (
                            <Box 
                                sx={{
                                    backgroundColor: selectedArticle === index ? "#f0f0f0" : "initial",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    borderLeft: selectedArticle === index ? "4px solid rgb(114, 51, 165)" : "4px solid rgb(135, 78, 161)",
                                    transition: "all 0.2s ease-in-out",
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "#f5f5f5",
                                    },
                                }}
                        >
                                <Typography
                                    key={index}
                                    variant="h6"
                                    color={
                                        selectedArticle === index
                                            ? "primary"
                                            : "initial"
                                    }
                                    onClick={() => setSelectedArticle(index)}
                                    fontSize={"18px"}
                                    sx={{ cursor: "pointer" }}
                                >
                                    {article.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Divider 
                    sx={{
                        "@media (max-width: 1000px)": {
                            display: "none",
                        },
                    }}
                    orientation="vertical" 
                />
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        padding: "20px",
                    }}
                >   
                    <Typography variant="h4">
                        {articles[selectedArticle].title}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                        }}
                    >
                        {articles[selectedArticle].content}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Support;
