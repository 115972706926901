/**
 * @file: RoutesProvider.tsx
 * @date: 26 Feb 2024
 * @description: $
 */

import React, {HTMLProps, JSX, useEffect} from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {AuthUser, T_Localization, T_UseAppContextResult, useAppContext, useLocalization} from "app/hooks";
import getViewForRoute from "views/";
import i18n from "i18next";
import useInactivityLogout from "../hooks/useInactivityLogout";

const PublicRoute = (user: AuthUser, children: any): React.ReactNode => {
    return user.isAuthenticated() ? <Navigate to="/dashboard" /> : children;
}

const PrivateRoutes = ({user, menus}: T_UseAppContextResult): React.ReactNode => {
    const menuList = menus.getMenuList();
    const applicationRoutes = menuList.map((menu, index) => {
        return <Route path={`${menu.path}`} key={menu.key} element={getViewForRoute(menu.path)} />
    })
    return (<Route path={"/"} key={"#privateview"} element={getViewForRoute('/private-view')}>
        {/*<Route path={"/auth"} key={"#/auth"} element={getViewForRoute("/auth")} />*/}
        {applicationRoutes}
    </Route>);
}



const ApplicationRoutes = (props: HTMLProps<JSX.Element>): JSX.Element => {
    const {user, menus} = useAppContext();
    const localization = useLocalization();

    useEffect(() => {
    const initializeLocalization = async () => {
      try {
        await i18n.changeLanguage(localization.languageCode);
      } catch (e) {
        console.log({e});
      }
    };
    initializeLocalization();
  }, [localization.languageCode]);

    // Define routes to ignore inactivity logout
    const routesToIgnore = ["wallethub-payment","cryptohub-payment","autenticacao"];
    useInactivityLogout(10 * 60 * 1000,routesToIgnore); // 10 minutes inactivity check



    return (

        <Routes>
            <Route path="/" key={"#PUB-/"} element={PublicRoute(user, getViewForRoute("/"))} />
            <Route path="/privacy-policy" key={"#PUB-/"} element={getViewForRoute("/privacy-policy")} />
            <Route path="/terms-and-conditions" key={"#PUB-/"} element={getViewForRoute("/terms-and-conditions")} />
            <Route path="/login" key={"#PUB-/login"} element={PublicRoute(user, getViewForRoute("/login"))} />
            <Route path="/autenticacao" key={"#PUB-/autenticacao"} element={getViewForRoute("/autenticacao")} />
            <Route path="/signup" key={"#PUB-/signup"} element={PublicRoute(user, getViewForRoute("/signup"))} />
            <Route path="/payment/:clientId" key={"#PUB-/payment"} element={PublicRoute(user, getViewForRoute("/payment"))} />

            <Route path="/moneyhub-payment/:transactionId/:paymentId?" key={"#PUB-/moneyhub-payment"} element={getViewForRoute("/moneyhub-payment")} />
            <Route path="/cryptohub-payment/:transactionId/settle-payment" key={"#PUB-/cryptohub-settle-payment"} element={getViewForRoute("/cryptohub-settle-payment")} />
            <Route path="/cryptohub-payment/:transactionId?/:paymentId?" key={"#PUB-/cryptohub-payment"} element={getViewForRoute("/cryptohub-payment")} />
            <Route path="/flexhub-payment/:transactionId?/:paymentId?" key={"#PUB-/flexhub-payment"} element={getViewForRoute("/flexhub-payment")} />
            <Route path="/wallethub-payment/:transactionId?/:paymentId?" key={"#PUB-/wallethub-payment"} element={getViewForRoute("/wallethub-payment")} />
            <Route path="/my-crypt/:transactionId?" key={"#PUB-/my-crypt"} element={getViewForRoute("/my-crypt")} />
            <Route path="/casino" key={"#PUB-/casino"} element={getViewForRoute("/casino")} />
            <Route path="/support" key={"#PUB-/support"} element={getViewForRoute("/support")} />

            <Route path="/not-found" element={getViewForRoute("/not-found")} />
            <Route path="/" element={user.isAuthenticated() ? <Outlet /> : <Navigate to="/login" />}>
                {(user.isAuthenticated() && menus.isValid()) ? PrivateRoutes({user, menus}) : null}
            </Route>
            {/* Fallback for any unmatched routes */}

            <Route path="*" element={<Navigate to={"/not-found"} />} />
        </Routes>
    )

}

export default ApplicationRoutes;
