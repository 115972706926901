import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useLoader, useNotification } from "app/common";
import { Formik } from "formik";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { T_UseAccountStatus } from "app/hooks";
import { QUserAccount } from "app/queries";
import { pallete, styles } from "app/styles";
import { SearchInput } from "app/components/inputs";
import { getAxiosAPI } from "app/utils/axiosApiRequests";

const info_schema: any = Yup.object().shape({
  external_user_id: Yup.string().required("External user id is required"),
  dob: Yup.string()
    .required("Date of birth is required")
    .test("dob", "You must be at least 18 years old", (value) => {
      const today = dayjs();
      const dob = dayjs(value);
      const age = today.diff(dob, "year");
      return age >= 18;
    }),
  type: Yup.string().required("Type is required"),
  full_name: Yup.string().required("Full name is required"),
  country: Yup.string().required("Country is required"),
});

type T_AccountInfo = {
  dob: string;
  external_user_id: string;
  type: string;
  full_name: string;
  country: string;
};

const TravelRuleTransactionAddCounterPartyData = ({ updateAccountStatus }: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const [countries, setCountries] = useState([]);
  const addCounterPartyDataQuery = QUserAccount.useAddCounterPartyData();
  const notification = useNotification();
  const loader = useLoader();
  const travelRuleStatusQuery = QUserAccount.useTravelRuleStatus(true);

  if (travelRuleStatusQuery.result !== undefined && travelRuleStatusQuery.result.length === 0) {
    updateAccountStatus({ isTravelRuleTransactionAddCounterPartyDataCompleted: true });
  }

  const pendingRecord = travelRuleStatusQuery.result?.at(0);

  const updateAccountDetails = (data: T_AccountInfo) => {
    loader.show();
    addCounterPartyDataQuery.EXECUTE({
      payload: {
        travel_rule_transaction_id: pendingRecord?.id,
        counter_party_data_from_user: {
          externalUserId: data.external_user_id,
          fullName: data.full_name,
          placeOfBirth: data.country,
          dob: data.dob,
        },
        type: data.type,
      },
      onSuccessFn: () => {
        travelRuleStatusQuery.refetch();
        loader.hide();
      },
      onErrorFn: () => {
        loader.hide();
        notification.error("Failed to update counter party data!");
      },
    });
  };

  const handleSkip = () => {
    updateAccountStatus({ isTravelRuleTransactionAddCounterPartyDataCompleted: true });
  };

  const getCountry = async () => {
    loader.show();
    await getAxiosAPI("/country")
      .then((res: any) => {
        setCountries(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
        notification.error("Failed to fetch countries!");
      })
      .finally(() => {
        loader.hide();
      });
  };

  useEffect(() => {
    getCountry();
  }, []);

  return (
    <Dialog open={true}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "500px",
        }
      }}
    >
      <DialogTitle>Add Counter Party details</DialogTitle>
      <DialogContent>
        <Box className={classes.infoBox}>
          <Typography className={classes.infoText}>
            Transaction type: {pendingRecord?.transaction_type}
          </Typography>
          <Typography className={classes.infoText}>
            Amount in Euro: {pendingRecord?.amount_in_euro}
          </Typography>
          <Typography className={classes.infoText}>
            Transaction Hash: {pendingRecord?.transaction_hash}
          </Typography>
          <Typography className={classes.infoText}>
            Completed At: {pendingRecord?.completed_at}
          </Typography>
        </Box>
        <Formik
          key={pendingRecord?.id || "key"}
          initialValues={{
            dob: "",
            external_user_id: "",
            type: "individual",
            full_name: "",
            country: "",
          }}
          validationSchema={info_schema}
          onSubmit={updateAccountDetails}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }: any) => (
            <Box className={classes.formBox}>
              <TextField
                id="outlined-basic"
                label="External User ID"
                variant="outlined"
                className={classes.inputBox}
                value={values.external_user_id}
                onBlur={handleBlur("external_user_id")}
                onChange={handleChange("external_user_id")}
              />
              {touched.external_user_id && errors.external_user_id && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.external_user_id}
                </Typography>
              )}

              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                className={classes.inputBox}
                value={values.full_name}
                onBlur={handleBlur("full_name")}
                onChange={handleChange("full_name")}
              />
              {touched.full_name && errors.full_name && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.full_name}
                </Typography>
              )}

              <SearchInput
                options={countries}
                label="Select Country"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="country"
              />
              {touched.country && errors.country && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.country}
                </Typography>
              )}

              <SearchInput
                options={[
                  {
                    id: "individual",
                    name: "Individual",
                  },
                  {
                    id: "company",
                    name: "Company",
                  },
                ]}
                label="Select type"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="type"
              />
              {touched.type && errors.type && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.type}
                </Typography>
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%", margin: "10px 0" }}
                  label="Date Of Birth"
                  value={values.dob}
                  format={"DD-MM-YYYY"}
                  onChange={(newValue) => {
                    setFieldValue(
                      "dob",
                      newValue ? newValue.toISOString() : ""
                    );
                  }}
                />
              </LocalizationProvider>
              {touched.dob && errors.dob && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.dob}
                </Typography>
              )}
              <DialogActions>
                <Button onClick={handleSkip} color="primary">
                  Skip for now
                </Button>
                <Button onClick={() => handleSubmit()} color="primary">
                  Send
                </Button>
              </DialogActions>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TravelRuleTransactionAddCounterPartyData;

const useStyles = makeStyles()((theme) => {
  return {
    infoBox: {
      padding: "10px",
      backgroundColor: "#f5f5f5",
      marginBottom: "10px",
    },

    infoText: {
      padding: "5px",
    },

    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },
  };
});
