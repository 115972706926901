import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {makeStyles} from "tss-react/mui";
import {pallete, styles} from "app/styles";
import {useNavigate} from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {IconButton, List, ListItem, ListItemButton, Popover,} from "@mui/material";

export default function LandingPageHeader({ handleTabsClick }: any) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleLogoClick();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: scrolled ? pallete.primaryBlack : "transparent",
          boxShadow: scrolled ? "0px 2px 4px -1px rgba(0,0,0,0.4)" : "none",
          padding: scrolled ? "0px" : "15px 0px",
        }}
      >
        <Toolbar className={classes.toolBar}>
          <Box
            component="img"
            className={classes.iconWeb}
            src="assets/logos/tradeBpLogin.svg"
            alt="Descriptive Alt Text"
            onClick={() => handleLogoClick()}
          />
          <Box className={classes.menue}>
            <Typography
              variant="h6"
              onClick={() => handleTabsClick("home")}
              className={classes.menuTypos}
            >
              Home
            </Typography>

            <Typography
              variant="h6"
              onClick={() => handleTabsClick("product")}
              className={classes.menuTypos}
            >
              Product
            </Typography>

            <Typography
              variant="h6"
              onClick={() => handleTabsClick("platform")}
              className={classes.menuTypos}
            >
              Platform
            </Typography>

            <Typography
              variant="h6"
              onClick={() => handleTabsClick("aboutus")}
              className={classes.menuTypos}
            >
              About Us
            </Typography>

            <Typography
              variant="h6"
              onClick={() => handleTabsClick("faq")}
              className={classes.menuTypos}
            >
              FAQ
            </Typography>
            <Typography
              variant="h6"
              onClick={() => handleTabsClick("contactus")}
              className={classes.menuTypos}
            >
              Contact us
            </Typography>
          </Box>
          <Box className={classes.btnBox}>
            <Button
              onClick={() => navigate("/login")}
              variant="contained"
              className={classes.button0}
            >
              Sign in
            </Button>
            <Button
              onClick={() => navigate("/signup")}
              variant="contained"
              className={classes.button1}
            >
              Get Started
            </Button>
          </Box>
          <Box className={classes.icnBtnBox}>
            <IconButton onClick={handleClick}>
              <MenuRoundedIcon
                sx={{ fontSize: "45px", color: pallete.primaryWhite }}
              />
            </IconButton>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 50,
                horizontal: 50,
              }}
              sx={{ transition: "transform 0.3s ease, opacity 0.3s ease" }}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleClose();
                      handleTabsClick("home");
                    }}
                  >
                    <Typography variant="h6">Home</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleTabsClick("aboutus")}>
                    <Typography variant="h6">About Us</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleTabsClick("platform")}>
                    <Typography variant="h6">Platform</Typography>
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton>
                    <Typography
                      variant="h6"
                      onClick={() => {
                        handleClose();
                        handleTabsClick("product");
                      }}
                    >
                      Product
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Typography
                      variant="h6"
                      onClick={() => {
                        handleClose();
                        handleTabsClick("faq");
                      }}
                    >
                      FAQ
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Typography
                      variant="h6"
                      onClick={() => {
                        handleClose();
                        handleTabsClick("contactus");
                      }}
                    >
                      Contact Us
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Typography variant="h6" onClick={() => navigate("/login")}>
                      Sign In
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <Typography
                      variant="h6"
                      onClick={() => navigate("/signup")}
                    >
                      Sign Up
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const useStyles = makeStyles()((theme) => {
  return {
    icnBtnBox: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    btnBox: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    button0: {
      borderRadius: 0,
      // backgroundColor:pallete.primaryWhite,
      color: pallete.primaryWhite,
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "none",
      padding: "10px 30px",
      "&:hover": {
        color: pallete.primaryWhite,
        backgroundColor: pallete.primaryPurple,
      },
    },
    button1: {
      borderRadius: 0,
      backgroundColor: pallete.primaryWhite,
      color: pallete.primaryPurple,
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "none",
      padding: "10px 30px",
      "&:hover": {
        color: pallete.primaryWhite,
        backgroundColor: pallete.primaryPurple,
      },
    },
    menuTypos: {
      padding: "0 20px",
      fontWeight: 500,
      cursor: "pointer",
      "&:hover": {
        color: "#ab288f",
      },
    },
    menue: {
      ...styles.flexDRS,
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    toolBar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      padding: "10px 50px",
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px 20px",
      },
    },
    iconWeb: {
      height: "60px",
      cursor: "pointer",
    },
  };
});
