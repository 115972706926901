import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {useAppContext, useAuthenticator} from "app/hooks";
import {useLoader, useNotification} from "app/common";

const OAuthAuthenticator: React.FC = () => {
    const notification = useNotification();
    const loader = useLoader();
    const authenticator = useAuthenticator();
    const {user} = useAppContext();
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.replace("#", "?"));
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");

    const authenticateUser = async () => {
        if (accessToken && refreshToken) {
            notification.success("Verifying.. Please wait");
            return authenticator.loginWithCredentials(accessToken, refreshToken, "gov");
        }};

    useEffect(() => {
        loader.show()
        console.log("AccessToken- ", accessToken);
        !user.isAuthenticated() && authenticateUser()
    }, []);

    if (user.isAuthenticated()){
        loader.hide();
       return <Navigate to={"/dashboard"} />
    }
}

export default OAuthAuthenticator;
