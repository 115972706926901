import {KEYS, URLS} from "./QueryConstants";

import {RQ} from "app/hooks"


/**
 * Custom hook to fetch currency exchange rates from the API.
 * @returns An object containing the result of the API query.
 */

export type T_CurrencyExchangeRates = {
    crypto: Map<string, number>;
    fiat: Map<string, number>;
}

export const useCurrencyExchangeRatesQuery = (): RQ.T_QueryResult<T_CurrencyExchangeRates> => {
    // Use the useQueryRequest hook with the defined query options
    return RQ.useQueryRequest<T_CurrencyExchangeRates>({
        queryKey: KEYS.CURRENCY_EXCHANGE_RATES,
        url: URLS.CRYPTO.GET_CURRENCY_EXCHANGE_RATES_FROM_CRYPTO,
    });
}

/**
 * Custom hook to fetch assets list from the API.
 * @returns An object containing the result of the API query.
 */

export type T_Asset = {
    id: string;
    rank: string;
    symbol: string;
    name: string;
    supply: string;
    maxSupply: string;
    marketCapUsd: string;
    volumeUsd24Hr: string;
    priceUsd: string;
    changePercent24Hr: string;
    vwap24Hr: string;
    explorer: string
}

type T_AssetList = T_Asset[];

export interface T_AssetListResponse {
    data?: T_AssetList;
    timestamp?:number;
}

export const useAssetsList = (limit: number = 10): RQ.T_QueryResult<T_AssetList> => {
    const useAssetsListQueryResult =  RQ.useQueryRequest<T_AssetList>({
        url: URLS.CRYPTO.GET_ASSETS_FROM_COINCAP,
        queryKey: KEYS.ASSETS_LIST,
        refetchInterval: 30000,
    });

    let parsedData = {result:[]};
    if  (useAssetsListQueryResult.isResolved ) {
        try {
            const jsonResult = JSON.parse(useAssetsListQueryResult.result.toString());
            parsedData = {result: jsonResult?.data || [] as T_AssetList, }
        }catch (e) {
            //@ts-ignore
            parsedData= {result: useAssetsListQueryResult.result || [] as T_AssetList, }
        }
    }

    return {...useAssetsListQueryResult,...parsedData}
}


/**
 * Custom hook to fetch best price from the API.
 * @returns An object containing the result of the API query.
 */

export type T_BestPrice = {
    price: number;
}


export const useBestPriceAPI = (): RQ.T_QueryResult<T_BestPrice> => {
    // Use the useQueryRequest hook with the defined query options
    return RQ.useQueryRequest<T_BestPrice>({
        queryKey: KEYS.BEST_PRICE,
        url: URLS.CRYPTO.GET_BEST_PRICE_FROM_SERVICE,
    });
}
