import React from "react";
import {TabContent} from "app/components/tabs";
import {useFinanceReportStyles} from "./FinanceReportStyles";
import {TradeOrdersView} from "./trade-orders";
import {MoneyHubOrdersView} from "./moneyhub-orders";
import {CryptoHubOrdersView} from "./cryptohub-orders";
import { FlexHubOrdersView } from "./flexhub-orders";
import Withdraw from "./withdraw";


const FinanceReportView = () => {
    const {classes} = useFinanceReportStyles();
    const tabHeading: string = "Finance Reports";
    const resetUrlOnTabChange: boolean = true;


    const tabData = [
        {label: "Trade Orders", component: <TradeOrdersView />},
        {label: "MoneyHub Orders", component: <MoneyHubOrdersView />},
        {label: "CryptoHub Orders", component: <CryptoHubOrdersView />},
        {label: "FlexHub Orders", component: <FlexHubOrdersView />},
        {label: "Withdraw", component: <Withdraw />},
    ];

    return TabContent({tabHeading, tabData, resetUrlOnTabChange});
};
export default FinanceReportView;
