import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import { Formik } from "formik";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import * as Yup from "yup";
import { SearchInput } from "app/components/inputs";
import { useWhitelistedAddressMutation } from "../WithdrawQueries";
import { usePermissions } from "app/hooks";
import { useUserWalletQuery } from "app/queries/UserWalletQueries";
import { get } from "react-hook-form";

const form_schema = Yup.object().shape({
  walletAddress: Yup.string().required("Wallet Address is required"),
  vault: Yup.string().required("Vault is required"),
  quantity: Yup.number()
    .required("Quantity is required")
    .min(1, "Quantity must be greater than 0"),
});

export const AddWithdraw = () => {
  /** @var _permissions : T_Permission  the permissions for this component **/
  const _permissions = usePermissions();
  // State for form fields
  const { classes } = useStyles();
  const [wallets, setWallet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [assets, setAssets] = useState([]);
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [vault, setVault] = useState([]);
  const [selectedVault, setSelectedVault] = useState("");
  const useWhitelistedAddressMutationRequest = useWhitelistedAddressMutation();
  const userWalletQuery = useUserWalletQuery(selectedVault);

  // Handle form submission
  const handleAPIcall = async (values: any) => {
    if (!_permissions.canCreate()) {
      return _permissions.warn();
    }
    const payload = {
      whitelist_id: values.walletAddress,
      quantity: values.quantity,
      include_fees: values.includeFees,
      vault_id: values.vault,
    };
    useWhitelistedAddressMutationRequest.EXECUTE({
      payload,
      onSuccessFn: () => {
        setSnackbarMessage("Withdraw request submitted successfully");
        setSeverity("success");
        setShowSnackbar(true);
      },
      onErrorFn: (error: any, message: any) => {
        setSnackbarMessage(message);
        setSeverity(message);
        setShowSnackbar(true);
      },
    });
  };

  const getWallets = async () => {
    setLoading(true);
    await getAxiosAPI("/user/whitelisted-wallet-address", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setWallet(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getVault = async () => {
    await getAxiosAPI("/user-account/vault", true)
      .then((res: any) => {
        const vaults = res.data.data;
        for (let i = 0; i < vaults.length; i++) {
          vaults[i].nick_name = vaults[i].nick_name || `Vault ${i + 1}`;
        }
        setVault(vaults);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getAsset = async () => {
    await getAxiosAPI("/asset", true)
      .then((res: any) => {
        setAssets(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getWallets();
    getAsset();
    getVault();
  }, []);

  const getBalance = (walletAddress: string) => {
    return 0;
  };

  return (
    // <Box className={classes.mainBox}>
    // </Box>
    <Box className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Typography variant="h3" className={classes.formHeading}>
        Withdrawal Form
      </Typography>
      <Formik
        initialValues={{
          walletAddress: "",
          vault: "",
          quantity: 0,
          includeFees: false,
          maxBalance: false,
        }}
        validationSchema={form_schema}
        onSubmit={(values: any) => {
          handleAPIcall(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }: any) => (
          <Grid container>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={vault}
                label="Select Vault"
                onSelect={(field: any, value: any) => {
                  setFieldValue(field, value)
                  setSelectedVault(value);
                }}
                displayValue="nick_name"
                formikValue="vault"
                renderOption={(props: any, option: any) => (
                  <Box {...props}>
                    <Typography variant="subtitle1">{option.nick_name}</Typography>
                  </Box>
                )}
              />
              {touched.vault && errors.vault && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.vault}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={wallets}
                label="Select Wallet Address"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="wallet_address"
                formikValue="walletAddress"
                renderOption={(props: any, option: any) => (
                  <Box {...props}>
                    <Typography variant="subtitle1">
                      {option.nick_name} ({option.wallet_address}) (
                      {option.fireblocks_asset_id})
                    </Typography>
                  </Box>
                )}
              />
              {touched.walletAddress && errors.walletAddress && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.walletAddress}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                className={classes.inputBox}
                label="Quantity"
                name="quantity"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={values.quantity}
                onChange={handleChange("quantity")}
                onBlur={handleBlur("quantity")}
                type="number"
                variant="outlined"
                disabled={values.maxBalance}
              />
              {touched.quantity && errors.quantity && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.quantity}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                className={classes.inputBox}
                label="Balance"
                name="balance"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                value={getBalance(values.walletAddress)}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="includeFees"
                      onChange={handleChange("includeFees")}
                      checked={values.includeFees}
                      disabled={values.maxBalance}
                    />
                  }
                  label="Include Fees"
                />
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="maxBalance"
                      onChange={(event) => {
                        setFieldValue("maxBalance", event.target.checked)
                        if(event.target.checked){
                          setFieldValue("quantity", getBalance(values.walletAddress))
                          setFieldValue("includeFees", event.target.checked)
                        } else {
                          setFieldValue("quantity", 0)
                          setFieldValue("includeFees", false)
                        }
                      }}
                    />
                  }
                  label="Max balance"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonBox}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};



const useStyles = makeStyles()((theme) => {
  return {
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    gridItemPadding: {
      padding: "5px 10px",
    },
    routeName: {},
    bottomText: {
      position: "absolute",
      bottom: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    termsText: {
      color: pallete.primaryBlack,
      textDecorationLine: "underline",
      margin: "auto 0",
    },
    checkBoxB: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 50px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      ...styles.webButton,
      padding: "10px 60px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      // alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
