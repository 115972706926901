import React from "react";
import { TabContent } from "app/components/tabs";
import { WithdrawHistoryTable } from "./withdrawHistory";


const Withdraw = () => {
    const tabHeading: string = "Withdraw";
    const resetUrlOnTabChange: boolean = true;

    const tabData = [
        {label: "History", component: <WithdrawHistoryTable />},
    ];

    // return TabContent({tabHeading, tabData, resetUrlOnTabChange});
    return <WithdrawHistoryTable />;
};

export default Withdraw;
