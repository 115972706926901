/**
 * @file: useAccountStatus.ts
 * @date: 12 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import {useLocalStorage} from "../useStorage";
import {useState} from "react";


export type T_AccountStatus = {
    isKycCompleted: boolean;
    isEmailVerified: boolean;
    is2faActive: boolean;
    is2faCompleted: boolean;
    isPasswordCreated: boolean;
    isProfileCompleted: boolean;
    isTravelRuleTransactionAddCounterPartyDataCompleted: boolean;
}

export class AccountStatus {
    private readonly status: T_AccountStatus;

    constructor(status: T_AccountStatus) {
        this.status = status
    }

    public isCompleted(): boolean {
        return (
            !this.isKycRequired()
            && !this.isEmailVerificationRequired()
            && !this.is2faPasscodeRequired()
        );
    }

    public is2faPasscodeRequired(): boolean {
        return (this.status.is2faActive && !this.status.is2faCompleted);
    }

    public is2faSetupRequired(): boolean {
        return (!this.status.is2faActive);
    }

    public isEmailVerificationRequired(): boolean {
        return (!this.status.isEmailVerified);
    }

    public isKycRequired(): boolean {
        return (!this.status.isKycCompleted);
    }

    public isPasswordCreationRequired(): boolean {
        return (!this.status.isPasswordCreated);
    }

    public isProfileIncomplete(): boolean {
        return !this.status.isProfileCompleted;
    }

    public isTravelRuleTransactionAddCounterPartyDataRequired(): boolean {
        return !this.status.isTravelRuleTransactionAddCounterPartyDataCompleted;
    }

    public getStatus(): T_AccountStatus {
        return this.status;
    }
}


export type T_UseAccountStatus = {
    accountStatus: AccountStatus;
    updateAccountStatus: (newStatus: Partial<T_AccountStatus>) => void;
}

export const useAccountStatus = (): T_UseAccountStatus => {
    const dataKey: string = 'AHSFQcns3LY6PJdZ3GFV';
    const _storage = useLocalStorage<T_AccountStatus>(dataKey, {
        isKycCompleted: false,
        isEmailVerified: false,
        is2faActive: false,
        is2faCompleted: false,
        isPasswordCreated:false,
        isTravelRuleTransactionAddConuterPartyDataCompleted: false
    });

    const [accountStatus, setAccountStatus] = useState<AccountStatus>(() => {
        return new AccountStatus(_storage.getValue());
    });


    const updateAccountStatus = (newStatus: Partial<T_AccountStatus>): void => {
        const currentStatus = accountStatus.getStatus();

        const updatedAccountStatus: AccountStatus = new AccountStatus({
            isKycCompleted: newStatus.isKycCompleted ?? currentStatus.isKycCompleted,
            isEmailVerified: newStatus.isEmailVerified ?? currentStatus.isEmailVerified,
            is2faActive: newStatus.is2faActive ?? currentStatus.is2faActive,
            is2faCompleted: newStatus.is2faCompleted ?? currentStatus.is2faCompleted,
            isPasswordCreated: newStatus.isPasswordCreated ?? currentStatus.isPasswordCreated,
            isProfileCompleted: newStatus.isProfileCompleted ?? currentStatus.isProfileCompleted,
            isTravelRuleTransactionAddCounterPartyDataCompleted: newStatus.isTravelRuleTransactionAddCounterPartyDataCompleted ?? currentStatus.isTravelRuleTransactionAddCounterPartyDataCompleted
        });
        _storage.setValue(updatedAccountStatus.getStatus());

        setAccountStatus(updatedAccountStatus);
    }

    return {accountStatus, updateAccountStatus};

}
