import React from "react";
import { QFlexhubQueries as QCHQ } from "app/queries";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { getStatusBadgePropsForTradeOrder, redirectTo } from "app/utils";
import { pallete } from "app/styles";

const getStatusColor = (status: string): any => {
  switch (status) {
    case "-1":
      return pallete.selections.red;
    case "0":
      return pallete.selections.yellow;
    case "1":
      return pallete.selections.blue;
    case "2":
      return pallete.selections.green;
    case "3":
      return pallete.selections.pink;
    default:
      return pallete.selections.brown;
  }
};
const renderFunction = (data: any): React.JSX.Element[] => {
  const transactionStatusColour = getStatusColor(
      data.status_id?.toString() || ""
    );
    return [
      <TDF.TextContent value={data.sender_email} />,
      <TDF.TextContent value={data.receiver_email} />,
      <TDF.TextContent value={data.payment_id} />,
      // <TDF.TextContent value={data.user_id_in_merchant_site} />,
      <TDF.TextContent value={data.currency_symbol} />,
      <TDF.TextContent value={data.fireblocks_asset_id} />,
      <TDF.NumericContent value={data.received_quantity} />,
      <TDF.NumericContent value={`${data.amount}`} />,
      <TDF.NumericContent value={`${data.fee}`} />,
      <TDF.NumericContent value={data.network_fee} />,
      <TDF.StatusBadge
        statusText={data.status_name}
        color={transactionStatusColour}
      />,
      <TDF.DateTimeContent dateTime={(data?.created_at ? data.created_at.toString() : '')} />,
    ];
};

const flexhubOrdersDataMap = {
  User: "sender_email",
  Merchant: "receiver_email",
  // "User ID": "user_id_in_merchant_site",
  "Payment ID": "payment_id",
  Currency: "currency_symbol",
  "Asset": "fireblocks_asset_id",
  "Received Quantity": "received_quantity",
  Amount: "amount",
  Fee: "fee",
  "Network Fee": "network_fee",
  Status: "status_name",
  "Date": "created_at",
};

export const FlexHubOrders = (): React.JSX.Element => {
  const [flexhubSearchParams, flexhubOrdersQuery] =
    QCHQ.useFlexhubOrdersSearchQuery();
  const headings: string[] = Object.keys(flexhubOrdersDataMap);
  const tableData = flexhubOrdersQuery.isResolved
    ? flexhubOrdersQuery.result.records
    : [];

  // const getMenuItemOptionsFn = async (
  //   data: any
  // ): Promise<TDF.T_MenuItemOptionList> => {
  //   const viewAllTransactions = () => redirectTo({ query: { mhuid: data.id } });
  //   return new Promise((resolve, reject) => {
  //     resolve([
  //       { name: "View All Transactions", onClickHandler: viewAllTransactions },
  //     ]);
  //   });
  // };

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Flexhub Orders",
    headings: headings,
    isLoading: flexhubOrdersQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    actionColumn: true,

    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: [
        "sender_email",
        // "user_id_in_merchant_site",
        "currency_symbol",
        "fireblocks_asset_id",
        "amount",
        "fee",
        "network_fee",
        "status_name",
        "payment_id",
      ],
    },
    sorterOptions: {
      sortKeys: {
        "Merchant": "receiver_email",
        "Date": "created_at",
      }
    },
    downloadOptions: {
      fileName: "flexhub-orders.csv",
      dataMap: flexhubOrdersDataMap,
    },
    // actionOptions: { getMenuItemOptionsFn },
  };

  return <DefaultTableComponent {...tableComponentProps} />;
};
