import React from "react";
import { QCryptohubQueries as QCHQ } from "app/queries";
import {
  DefaultTableComponent,
  T_DefaultTableComponentProps,
  TDF,
} from "app/components/table";
import { getStatusBadgePropsForTradeOrder, redirectTo } from "app/utils";

const renderFunction = (data: any): React.JSX.Element[] => {
  const keyPrefix: string = `moneyhub_transaction_${data.id}`;
  const statusProps = getStatusBadgePropsForTradeOrder(
    data.status_id.toString(),
    data.status_name
  );
  return [
    <TDF.TextContent value={data.sender_email} key={`${keyPrefix}-MNYUSR`} />,
    <TDF.TextContent
      value={`${data.receiver_email}`}
      key={`${keyPrefix}-MRCHNT`}
    />,
    <TDF.NumericContent value={`${data.amount}`} key={`${keyPrefix}-AMT`} />,
    <TDF.TextContent
      value={`${data.currency_symbol}`}
      key={`${keyPrefix}-CRR`}
    />,
    <TDF.NumericContent value={`${data.fees}`} key={`${keyPrefix}-ORDF`} />,
    <TDF.NumericContent
      value={`${data.fireblocks_asset_id}`}
      key={`${keyPrefix}-ASTN`}
    />,
    <TDF.TextContent
      value={`${data.asset_quantity}`}
      key={`${keyPrefix}-ASTQ`}
    />,
    <TDF.StatusBadge {...statusProps} key={`${keyPrefix}-STA`} />,
    <TDF.DateTimeContent
      dateTime={data?.verified_at?.toString() || ""}
      key={`${keyPrefix}-ORD`}
    />,
  ];
};

const cryptohubOrdersDataMap = {
  // User: "email",
  // Merchant: "full_name",
  // Amount: "amount",
  // Currency: "currency_symbol",
  // Fees: "fees",
  // "Fireblocks Asset": "fireblocks_asset_id",
  // Quantity: "asset_quantity",
  // Status: "status_id",
  // Date: "verified_at",
  //////////

  User: "sender_email",
  Merchant: "receiver_email",
  Amount: "amount",
  Currency: "currency_symbol",
  Fees: "fees",
  Asset: "asset_name",
  Quantity: "asset_quantity",
  Status: "status_name",
  Date: "created_at",
};

export const CryptoHubOrders = (): React.JSX.Element => {
  const [cryptohubSearchParams, cryptohubOrdersQuery] =
    QCHQ.useCryptohubOrdersSearchQuery();
  const headings: string[] = Object.keys(cryptohubOrdersDataMap);
  const tableData = cryptohubOrdersQuery.isResolved
    ? cryptohubOrdersQuery.result.records
    : [];

  const getMenuItemOptionsFn = async (
    data: any
  ): Promise<TDF.T_MenuItemOptionList> => {
    const viewAllTransactions = () => redirectTo({ query: { mhuid: data.id } });
    return new Promise((resolve, reject) => {
      resolve([
        { name: "View All Transactions", onClickHandler: viewAllTransactions },
      ]);
    });
  };

  const tableComponentProps: T_DefaultTableComponentProps = {
    title: "Cryptohub Orders",
    headings: headings,
    isLoading: cryptohubOrdersQuery.isResolving,
    initialRowsPerPage: 10,
    tableData: tableData,
    actionColumn: true,

    renderFunction: renderFunction,
    searchOptions: {
      searchKeys: [
        // "email",
        // "full_name",
        // // "mobile",
        // "amount",
        // "currency_symbol",
        // "fees",
        // "fireblocks_asset_id",
        // "asset_quantity",
        // "status_id",
        // "verified_at",
        //////////////////////////

        "sender_email",
        "receiver_email",
        "amount",
        "currency_symbol",
        "fees",
        "asset_name",
        "asset_quantity",
        "status_name",
        "created_at",
      ],
    },
    sorterOptions: {
      sortKeys: {
        "Merchant": "receiver_email",
        "Date": "created_at",
      }
    },
    downloadOptions: {
      fileName: "cryptohub-orders.csv",
      dataMap: cryptohubOrdersDataMap,
    },
    actionOptions: { getMenuItemOptionsFn },
  };

  return <DefaultTableComponent {...tableComponentProps} />;
};
