import React, { useEffect, useRef } from "react";
import {Box} from "@mui/material";
import LandingPageHeader from "app/components/unsorted/LandingPageHeader";
import AboutUs from "./sections/AboutUs";
import Home from "./sections/Home";
import Product from "./sections/Product";
import Platform from "./sections/Platform";
import Faq from "./sections/Faq";
import Footer from "./sections/Footer";
import Certificates from "./sections/Certificates";
import Security from "./sections/Security";
import Wallet from "./sections/Wallet";
import Verification from "./sections/Verification";

export const LandingPage = () => {
  const home = useRef(null);
  const products = useRef(null);
  const faq = useRef(null);
  const contact = useRef(null);
  const platform = useRef(null);
  const aboutus = useRef(null);

  const scrollToDiv = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleTabsClick = (type: string) => {
    switch (type.toString().toLowerCase()) {
      case "home":
        scrollToDiv(home);
        break;
      case "product":
        scrollToDiv(products);
        break;
      case "faq":
        scrollToDiv(faq);
        break;
      case "contactus":
        scrollToDiv(contact);
        break;
      case "platform":
        scrollToDiv(platform);
        break;
      case "aboutus":
        scrollToDiv(aboutus);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <LandingPageHeader handleTabsClick={handleTabsClick} />

      {/* Home  */}

      <Box ref={home}>
        <Home />
      </Box>

      {/* PRODUCT  */}

      <Box ref={products}>
        <Product />
      </Box>

      {/* Security */}

      <Security />

      {/* Our Platform */}

      <Box ref={platform}>
        <Platform />
      </Box>

      {/* Wallet */}

      <Wallet />

      {/* verification  */}

      <Verification />

      {/* About US */}

      <Box ref={aboutus}>
        <AboutUs />
      </Box>

      {/* FAQ */}

      <Box ref={faq}>
        <Faq />
      </Box>

      {/* certificates  */}

      <Certificates />

      {/* Footer */}

      <Box ref={contact}>
        <Footer handleTabsClick={handleTabsClick} />
      </Box>
    </Box>
  );
};
