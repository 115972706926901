import React, { useEffect, useState } from "react";
import { Box, Card, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { styles } from "app/styles";

import { useAssetsList } from "app/queries/CryptoQueries";

export const DashboardCardPrimary = ({ data }: { data: any }) => {
  const { classes } = useStyles();
  return (
      <Card className={classes.primaryCard}>
        <Typography color="primary" variant="h6" align="center">
          {data.title}
        </Typography>
        <Box className={classes.midBoxC0}>
          {data.innerData.map((x: any) => (
              <Box key={x.id} className={classes.midBoxC1}>
                {x.img ? (
                    <Box component="img" src={x.img} className={classes.centerImg} />
                ) : null}
                <Typography variant="h3">{x.amount}</Typography>
              </Box>
          ))}
        </Box>
        <Divider />
        <Box sx={{ paddingTop: "10px" }}>
          <Typography align="center" variant="subtitle2">
            {data.subtile}
          </Typography>
          <Typography align="center" color="primary" variant="h6">
            {data.subtileAmt}
          </Typography>
        </Box>
      </Card>
  );
};
type T_DCSProps = {
  limit: number;
  mdSize: number;
}
export const DashboardCardSecondary = ({limit,mdSize}: T_DCSProps) => {
  const { classes } = useStyles();
  const cryptoAssets: any = useAssetsList();


  const isGrowing = (amt: number) => {
    return amt > 0;
  };

  if (cryptoAssets.isResolving) {
    return (
        <Grid container key={"xcon1"}>
          {[...Array(8)].slice(0, limit).map((i: any, index: number) => (
              <Grid
                  key={`${index}-${i}`}
                  item
                  md={mdSize}
                  sm={6}
                  xs={12}
                  className={classes.topCard1}
              >
                <Skeleton variant="rounded" width={165} height={60} />
              </Grid>
          ))}
        </Grid>
    );
  }

  if (cryptoAssets.isResolved) {
    return (
        <Grid container key={"xcon"}>
          {cryptoAssets.result.slice(0, limit).map((data: any, index: any) => (
              <Grid
                  item
                  md={mdSize}
                  sm={6}
                  xs={6}
                  key={`#${index}-${data.id}`}
                  className={classes.topCard1}
              >
                <Card className={classes.secondCard} key={`#SC-${index}`}>
                  <Box className={classes.card2Box1}>
                    <Typography color="primary" variant="h5">
                      {data.name}
                    </Typography>
                    <Box component="img" src={data.img} />
                  </Box>
                  <Box className={classes.card2Box1}>
                    <Box
                        className={
                          isGrowing(parseFloat(data.changePercent24Hr))
                              ? classes.plusRating
                              : classes.minusRating
                        }
                    >
                      <Typography
                          variant="subtitle1"
                          className={
                            isGrowing(parseFloat(data.changePercent24Hr))
                                ? classes.ratingAmtP
                                : classes.ratingAmtN
                          }
                      >
                        {data.type}
                        {parseFloat(data.changePercent24Hr).toFixed(2)}%
                      </Typography>
                      <Box
                          component="img"
                          className={classes.grphnIcons}
                          src={
                            isGrowing(parseFloat(data.changePercent24Hr))
                                ? "assets/icons/arrow-up-right.svg"
                                : "assets/icons/arrow-down-right.svg"
                          }
                      />
                    </Box>
                    <Typography color="primary" variant="h6">
                      ${parseFloat(data.priceUsd).toFixed(2)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
          ))}
        </Grid>
    );
  }
};


export const TooltipCard = ({
                              limit,
                              mdSize,
                            }: {
  limit: number;
  mdSize: number;
}) => {
  const { classes } = useStyles();
  const cryptoAssets: any = useAssetsList();
  const [currentIndex, setCurrentIndex] = useState(0);

  const isGrowing = (amt: number) => amt > 0;

  useEffect(() => {
    if (cryptoAssets.result && cryptoAssets.result.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex(
            // (prevIndex) => (prevIndex + 1) % cryptoAssets.result.length
            (prevIndex) => (prevIndex + 1) % 3
        );
      }, 8500); // Update every 1 minute

      return () => clearInterval(interval); // Cleanup on component unmount
    }
  }, [cryptoAssets.result]);

  if (cryptoAssets.isResolving) {
    return (
        <Grid container key={"xcon1"}>
          {[...Array(limit)].map((_, index) => (
              <Grid key={`${index}`} item className={classes.topCard1}>
                <Skeleton variant="rounded" width={200} height={60} />
              </Grid>
          ))}
        </Grid>
    );
  }

  if (cryptoAssets.isResolved) {
    const data = cryptoAssets.result[currentIndex]; // Get the current coin to display
    return (
        <Grid container key={"xcon"}>
          <Grid
              item
              key={`#${currentIndex}-${data?.id}`}
              className={classes.topCard1}
          >
            <Card className={classes.secondCard}>
              <Box className={classes.card2Box1}>
                <Typography color="primary" variant="h6">
                  {data?.name}
                </Typography>
                <Box
                    sx={{ marginLeft: 2 }}
                    className={
                      isGrowing(parseFloat(data?.changePercent24Hr))
                          ? classes.plusRating
                          : classes.minusRating
                    }
                >
                  <Typography
                      variant="subtitle1"
                      className={
                        isGrowing(parseFloat(data.changePercent24Hr))
                            ? classes.ratingAmtP
                            : classes.ratingAmtN
                      }
                  >
                    {data?.type}
                    {parseFloat(data?.changePercent24Hr).toFixed(2)}%
                  </Typography>
                  <Box
                      component="img"
                      className={classes.grphnIcons}
                      src={
                        isGrowing(parseFloat(data?.changePercent24Hr))
                            ? "assets/icons/arrow-up-right.svg"
                            : "assets/icons/arrow-down-right.svg"
                      }
                  />
                </Box>
              </Box>
              <Box className={classes.card2Box1}>
                <Typography color="primary" variant="h6">
                  ${parseFloat(data?.priceUsd).toFixed(2)}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
    );
  }

  return null;
};

TooltipCard.defaultProps = {
  limit: 1,
  mdSize: 4,
};

const useStyles = makeStyles()((theme) => {
  return {
    topCard1: {
      padding: "0 20px 10px 0",
      [theme.breakpoints.down("lg")]: {
        // padding: "10px",
      },
      // width: "100px",
    },
    ratingAmtP: {
      color: "#00E069",
      fontWeight: 600,
    },
    ratingAmtN: {
      color: "#E13A05",
      fontWeight: 600,
    },
    plusRating: {
      borderRadius: "4px",
      backgroundColor: "#E5FFF1",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    minusRating: {
      borderRadius: "4px",
      backgroundColor: "#FFF0EB",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "2px 5px",
    },
    ratingbox: {
      display: "flex",
      flexDirection: "row",
    },
    grphnIcons: {
      height: "15px",
      width: "15px",
      padding: "2px",
    },
    secondCard: {
      padding: "12px",
      // width: "165px",
      // margin: "0 20px 20px 0",
      height: "99px",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    card2Box1: {
      ...styles.flexDRC,
      justifyContent: "space-between",
    },
    centerImg: {
      height: "32px",
      width: "32px",
      margin: "auto 10px",
    },
    midBoxC0: {
      ...styles.flexDCC,
      height: "70%",
      // alignItems:"center",
      // alignContent:"center",
    },
    midBoxC1: {
      ...styles.flexDRC,
    },
    primaryCard: {
      padding: "12px 0",
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      height: "244px",
      width: "100%",
      // marginRight: "20px",
    },
  };
});
