/**
 * @file: PrivateView.ts
 * @date: 11 Mar 2024
 * @author: prakash.mishra@intimetec.com
 * @description: $
 */
import React from "react";
import {useAccountStatus} from "app/hooks";
import * as AccountStatus from "../account-status";
import {ApplicationView} from "./ApplicationView";

export const PrivateApplication = (): React.JSX.Element => {
    const userAccountStatus = useAccountStatus();


    if (userAccountStatus.accountStatus.isEmailVerificationRequired()) {
        return <AccountStatus.VerifyEmailAddress {...userAccountStatus} />;
    } else if (userAccountStatus.accountStatus.is2faSetupRequired()) {
        return <AccountStatus.SetupGoogle2FA {...userAccountStatus} />;
    } else if (userAccountStatus.accountStatus.is2faPasscodeRequired()) {
        return <AccountStatus.VerifyGoogle2FA {...userAccountStatus} />;
    } else if (userAccountStatus.accountStatus.isProfileIncomplete()) {
        return <AccountStatus.PersonalInfo {...userAccountStatus} />;
    } else if (userAccountStatus.accountStatus.isKycRequired()) {
        return <AccountStatus.IdentityVerification {...userAccountStatus} />;
    } else if (userAccountStatus.accountStatus.isTravelRuleTransactionAddCounterPartyDataRequired()) {
        return <AccountStatus.TravelRuleTransactionAddCounterPartyData {...userAccountStatus} />;
    } 
    else {
        return <ApplicationView {...userAccountStatus} />;
    }
};
